import React from 'react';
import SectionHeader from '@components/SectionHeader';
import PropTypes from 'prop-types';
import Grid, { Column, Row } from '@components/core/Grid';
import Section from '@components/core/Section';
import CarouselWithPadding from '@components/Carousels/CarouselWithPadding';
import WorkspaceCard from '@components/Cards/Workspace';
import styles from './workspace.module.scss';
import { BREAKPOINTS } from '../../../utils/const';

const Workspace = ({ data }) => {
    const { sectionHeader, content } = data;

    const settings = {
        centerPadding: 200,
        responsive: [
            {
                breakpoint: BREAKPOINTS.xl,
                settings: {
                    centerPadding: 200,
                },
            },
            {
                breakpoint: BREAKPOINTS.l,
                settings: {
                    centerPadding: 150,
                },
            },
            {
                breakpoint: BREAKPOINTS.md,
                settings: {
                    centerPadding: 100,
                },
            },
            {
                breakpoint: BREAKPOINTS.sm,
                settings: {
                    centerPadding: 50,
                },
            },
        ],
    };
    return (
        <Section className={styles.workspaceSectionHeader}>
            <Grid>
                <Row>
                    <Column md="6">
                        <SectionHeader
                            eyebrow={sectionHeader.eyebrow}
                            title={sectionHeader.title}
                            summary={sectionHeader.description}
                        />
                    </Column>
                </Row>
            </Grid>
            <CarouselWithPadding
                infinite
                isFullScreen
                settings={settings}
                sliderClassName={styles.carousel}
            >
                {content.map(cardInfo => (
                    <WorkspaceCard {...cardInfo} />
                ))}
            </CarouselWithPadding>
        </Section>
    );
};

Workspace.propTypes = {
    data: PropTypes.shape({
        sectionHeader: PropTypes.shape({
            eyebrow: PropTypes.string,
            title: PropTypes.string,
            description: PropTypes.string,
        }),
        content: PropTypes.arrayOf(PropTypes.object),
    }).isRequired,
};

export default Workspace;
