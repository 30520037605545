import React from 'react';
import PropTypes from 'prop-types';
import Grid, { Column, Row } from '@components/core/Grid';
import Section from '@components/core/Section';
import SectionHeader from '@components/SectionHeader';
import OddEvenCard from '@components/Cards/OddEvenCard';
import styles from './work-culture.module.scss';

const WorkCulture = ({ data }) => {
    const { content: workCulture, sectionHeader } = data;

    return (
        <Section className={styles.workCultureSection}>
            <Grid>
                <Row>
                    <Column md="9" data-testid="work-culture-header-section">
                        <SectionHeader
                            className={styles.headerSection}
                            eyebrow={sectionHeader.eyebrow}
                            title={sectionHeader.title}
                            summary={sectionHeader.description}
                        />
                    </Column>
                </Row>
            </Grid>
            <div
                className={styles.workCultureListContainer}
                data-testid="work-culture-info-section"
            >
                <div
                    className={styles.workCultureList}
                    data-testid="work-culture-list"
                >
                    {workCulture &&
                        workCulture.map((work, index) => (
                            <OddEvenCard
                                key={`item-${work.title}`}
                                className={styles.contentCard}
                                titleClassName={styles.title}
                                descriptionClassName={styles.description}
                                data={work}
                                position={index % 2 ? 'odd' : 'even'}
                            />
                        ))}
                </div>
            </div>
        </Section>
    );
};

WorkCulture.propTypes = {
    data: PropTypes.shape({
        sectionHeader: PropTypes.shape({
            eyebrow: PropTypes.string,
            title: PropTypes.string,
            description: PropTypes.string,
        }),
        content: PropTypes.arrayOf(
            PropTypes.shape({
                title: PropTypes.string,
                description: PropTypes.string,
                image: PropTypes.objectOf(PropTypes.any),
            })
        ),
    }).isRequired,
};

export default WorkCulture;
