import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import Text from '@components/core/Text';
import cx from 'classnames';

import styles from './odd-even-card.module.scss';

const OddEvenCard = ({
    data,
    position,
    className,
    imageClassName,
    titleClassName,
    descriptionClassName,
}) => {
    const positionStyle = styles[position];

    const classes = cx(styles.oddEvenCard, positionStyle, className);

    return (
        <div className={classes} data-testid="odd-even-card">
            <div
                className={`${styles.coverImageSection} ${positionStyle} ${imageClassName}`}
            >
                <Img
                    className={styles.coverImage}
                    fluid={data.image.childImageSharp.fluid}
                    alt={`Image - ${data.title}`}
                />
            </div>
            <div className={`${styles.descriptionSection} ${positionStyle}`}>
                <Text
                    size="h5"
                    as="h5"
                    className={`${styles.title} ${titleClassName}`}
                >
                    {data.title}
                </Text>
                <Text
                    size="paragraphSm"
                    as="p"
                    className={`${styles.title} ${descriptionClassName}`}
                >
                    {data.description}
                </Text>
            </div>
        </div>
    );
};

OddEvenCard.propTypes = {
    className: PropTypes.string,
    imageClassName: PropTypes.string,
    titleClassName: PropTypes.string,
    descriptionClassName: PropTypes.string,
    position: PropTypes.oneOf(['even', 'odd']).isRequired,
    data: PropTypes.shape({
        title: PropTypes.string,
        description: PropTypes.string,
        image: PropTypes.shape({
            publicURL: PropTypes.string,
            childImageSharp: PropTypes.shape({
                fluid: PropTypes.objectOf(PropTypes.any),
            }),
        }),
    }).isRequired,
};

OddEvenCard.defaultProps = {
    className: '',
    imageClassName: '',
    titleClassName: '',
    descriptionClassName: '',
};

export default OddEvenCard;
