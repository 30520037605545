import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import Text from '@components/core/Text';
import styles from './workspace.module.scss';

const Workspace = ({ image, description }) => {
    const { childImageSharp = {} } = image;
    return (
        <div className={styles.container}>
            {childImageSharp.fluid && <Img fluid={childImageSharp.fluid} />}
            <Text as="p" size="paragraphSm" className={styles.paragraph}>
                {description}
            </Text>
        </div>
    );
};

Workspace.propTypes = {
    image: PropTypes.shape({
        childImageSharp: PropTypes.shape({
            fluid: PropTypes.objectOf(PropTypes.any),
        }),
    }).isRequired,
    description: PropTypes.string.isRequired,
};

export default Workspace;
