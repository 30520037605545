import React from 'react';
import SectionHeader from '@components/SectionHeader';
import ArticleCard from '@components/Cards/Article';
import CarouselWithPadding from '@components/Carousels/CarouselWithPadding';
import PropTypes from 'prop-types';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import Grid, { Column, Row } from '@components/core/Grid';
import ParallaxSection from '@components/core/ParallaxSection';
import styles from './people-culture.module.scss';

const PeopleCulture = ({ data }) => {
    const { content } = data;
    const { md } = useBreakpoint();
    const cardLayoutBehavior = md ? 'horizontal' : 'vertical';

    return (
        <ParallaxSection
            variant="dark"
            dataTheme="dark"
            className={styles.culturePathSection}
            disabled={!md}
        >
            <Grid>
                <Row>
                    <Column md="6">
                        <SectionHeader
                            className={styles.cultureSectionHeader}
                            variant="dark"
                            eyebrow={data.sectionHeader.eyebrow}
                            title={data.sectionHeader.title}
                            summary={data.sectionHeader.description}
                        />
                    </Column>
                </Row>
            </Grid>
            <CarouselWithPadding infinite isFullScreen>
                {content.map(({ image, title, description, published }) => {
                    const article = {
                        image,
                        title,
                        overview: description,
                        published,
                    };
                    return (
                        <ArticleCard
                            key={article.published}
                            article={article}
                            sizeDescription="paragraphMd"
                            sizeTitle="h3"
                            colorTitle="white"
                            layoutDistribution={cardLayoutBehavior}
                            colorOverview="lightGrey"
                            classCardWrapper={styles.cardWrapper}
                            classImageWrapper={styles.imageWrapper}
                            classInfo={styles.info}
                            isFullScreen
                        />
                    );
                })}
            </CarouselWithPadding>
        </ParallaxSection>
    );
};

PeopleCulture.propTypes = {
    data: PropTypes.shape({
        sectionHeader: PropTypes.shape({
            eyebrow: PropTypes.string,
            title: PropTypes.string,
            description: PropTypes.string,
        }),
        content: PropTypes.arrayOf(PropTypes.object),
    }).isRequired,
};

export default PeopleCulture;
