import React from 'react';
import Layout from '@components/Layout';
import HeaderDescription from '@sections/Culture/HeaderDescription';
import WorkCulture from '@sections/Culture/WorkCulture';
import PeopleCulture from '@sections/Culture/PeopleCulture';
import Workspace from '@sections/Culture/Workspace';
import SEO from '@components/SEO';
import Section from '@components/core/Section';
import PageHeader from '@components/PageHeader';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

const CulturePage = ({
    data: {
        markdownRemark: {
            frontmatter: {
                title,
                seo,
                pageHeader,
                workCulture,
                peopleCulture,
                workspace,
                headerDescription,
            },
        },
    },
}) => {
    const seoData = {
        title: (seo && seo.title) || title,
        description: (seo && seo.description) || '',
        image: (seo && seo.image && seo.image.publicURL) || '',
    };

    return (
        <Layout navBarExternalBg>
            <SEO
                title={seoData.title}
                description={seoData.description}
                image={seoData.image}
            />
            <Section>
                <PageHeader infoHeader={pageHeader} />
                <HeaderDescription data={headerDescription} />
            </Section>
            <WorkCulture data={workCulture} />
            <PeopleCulture data={peopleCulture} />
            <Workspace data={workspace} />
        </Layout>
    );
};

CulturePage.propTypes = {
    data: PropTypes.objectOf(PropTypes.any).isRequired,
};

export const query = graphql`
    query CulturePage {
        markdownRemark(fields: { slug: { eq: "/pages/culture/" } }) {
            frontmatter {
                seo {
                    title
                    description
                    image {
                        publicURL
                    }
                }
                pageHeader {
                    title
                    headerDescription: description
                    image {
                        childImageSharp {
                            fluid {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                    previewUrl {
                        src: publicURL
                    }
                    videoUrl
                    videoCms {
                        src: publicURL
                    }
                }
                headerDescription {
                    description
                }
                workCulture {
                    sectionHeader {
                        eyebrow
                        title
                        description
                    }
                    content {
                        title
                        description
                        image {
                            childImageSharp {
                                fluid(maxWidth: 1300) {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
                    }
                }
                peopleCulture {
                    sectionHeader {
                        eyebrow
                        title
                        description
                    }
                    content {
                        title
                        description
                        image {
                            childImageSharp {
                                fluid {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
                    }
                }
                workspace {
                    sectionHeader {
                        eyebrow
                        title
                        description
                    }
                    content {
                        description
                        image {
                            childImageSharp {
                                fluid(maxWidth: 1300) {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

export default CulturePage;
