import React from 'react';
import PropTypes from 'prop-types';
import Text from '@components/core/Text';
import Grid from '@components/core/Grid';
import styles from './header-description.module.scss';

const HeaderDescription = ({ data }) => {
    return (
        <Grid>
            <Text className={styles.description} as="p" size="h5">
                {data.description}
            </Text>
        </Grid>
    );
};

HeaderDescription.propTypes = {
    data: PropTypes.shape({
        description: PropTypes.string,
    }).isRequired,
};

export default HeaderDescription;
